import image1 from "./images/img1.jpg";
import image2 from "./images/img2.png";
import image3 from "./images/img3.png";
import image4 from "./images/img4.jpg";
import image5 from "./images/img5.png";
import image6 from "./images/img6.png";
import image7 from "./images/img7.png";
import image8 from "./images/img8.png";
import image9 from "./images/img9.png";
import image10 from "./images/img10.png";
import image11 from "./images/img11.webp";
import image12 from "./images/img12.png";
import image13 from "./images/img13.png";

export const pictures = [
  {
    Pictures: image1,
    title: "A",
  },
  {
    Pictures: image2,
    title: "A",
  },
  {
    Pictures: image3,
    title: "A",
  },
  {
    Pictures: image4,
    title: "A",
  },
  {
    Pictures: image5,
    title: "A",
  },
  {
    Pictures: image6,
    title: "A",
  },
  {
    Pictures: image7,
    title: "A",
  },
  {
    Pictures: image8,
    title: "A",
  },
  {
    Pictures: image9,
    title: "A",
  },
  {
    Pictures: image10,
    title: "A",
  },
  {
    Pictures: image11,
    title: "A",
  },
  {
    Pictures: image12,
    title: "A",
  },
  {
    Pictures: image13,
    title: "A",
  },
];
